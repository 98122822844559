<template>
    <div>
        <v-main>
            <v-container fluid>
                <v-card>
                    <v-col cols="2">
                        <v-select
                            v-model="filterStatus"
                            :items="listStatus"
                            item-text="name"
                            item-value="id"
                            placeholder="Status Akun">
                        
                        </v-select>
                    </v-col>
                    <v-data-table
                        :headers="headers"
                        :items="listAccount"
                        :options.sync="pagination"
                        :loading=loading
                        :footer-props="footerProps"
                        :server-items-length="accountCount"
                    >
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-dialog
                                v-model="dialog"
                                max-width="500px"
                                >
                                <v-card>
                                    <v-card-text>
                                        <v-container>
                                            <v-row dense>
                                            <v-col
                                                cols="12"
                                                md="12"
                                            >
                                                <v-text-field
                                                v-model="remark"
                                                label="Keterangan revisi"
                                                ></v-text-field>
                                            </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-btn
                                                    color="red darken-1"
                                                    text
                                                    @click="cancel"
                                                >
                                                    Batal
                                                </v-btn>
                                                <v-btn
                                                    color="blue darken-1"
                                                    text
                                                    @click="revise"
                                                >
                                                    Revise
                                                </v-btn>

                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.account.statusDesc`]="{ item }">
                        <v-chip
                            :color="getColor(item.account.status)"
                            dark
                            small
                        >
                            {{ item.account.statusDesc == null ? 'DELETED' : item.account.statusDesc }}
                        </v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{item}">
                            <template v-if="item.account.status==51">
                                <v-btn class="mr-1" x-small color="success" @click="updateStatus(item, 53)">
                                    Approve
                                </v-btn>
                                <v-btn class="mr-1" x-small color="error" @click="showDialogRevise(item)">
                                    Revise
                                </v-btn>
                            </template>
                            <template v-if="item.account.status==52">
                                {{item.account.remark}}
                            </template>
                        </template>
                    </v-data-table>
                </v-card>
            </v-container>
        </v-main>
        <OverlayProgress :overlay=$root.overlay :isError=$root.isError />
    </div>
</template>
<script>
import OverlayProgress from '@/components/OverlayProgress.vue'

export default {
    components: {
        OverlayProgress
    },
    data() {
        return {
            headers: [
                { text: 'No Anggota', align: 'start', sortable: false, value: 'memberNo', },
                { text: 'Nama', sortable: false, value: 'fullName' },
                { text: 'Nama Akun', sortable: false, value: 'account.accountName' },
                { text: 'Nomor Akun', sortable: false, value: 'account.accountNumber' },
                { text: 'Bank', sortable: false, value: 'account.bankName' },
                { text: 'Status Akun', sortable: false, value: 'account.statusDesc' },
                { text: '', value: 'actions', sortable: false },
            ],
            listAccount: [],
            pagination: {
                page: 1,
                itemsPerPage: 20,
            },
            footerProps: {
                itemsPerPageOptions: [20, 50, 100, -1],
                showFirstLastPage: true,
                showCurrentPage: true,
            },
            loading: true,
            accountCount: 0,
            remark: null,
            dialog: false,
            item:null,
            bodyParam:null,
            filterStatus:null,
            listStatus: [
                {
                    id: '00', name: 'Semua'
                },
                {
                    id: '51', name: 'On Review'
                },
                {
                    id: '52', name: 'Revised'
                },
                {
                    id: '53', name: 'Approved'
                },
            ]

        }
    },
    watch:{
        pagination:{
            handler(){
                this.getData(this.filterStatus)
            },
            deep:true
        },
        'filterStatus': function() {
            this.getData(this.filterStatus)
        }
    },
    methods: {
        async getData(filterStatus) {
            if (filterStatus === undefined || filterStatus === '00' || filterStatus === null) filterStatus = ''
            this.loading=true
            var api = this.$root.api.get.accountSubmission+'?statusCode='+filterStatus
            const {page, itemsPerPage} = this.pagination
            if (itemsPerPage > -1) {
                api = api+'&pageNo='+page+'&rowPerPage='+itemsPerPage
            }
            await this.$root.api.sentAPI(
            {
                method: 'get',
                api: api,
            }).then((res) => {
                if (res.responseStatus.responseCode==='00') {
                    this.accountCount = res.data.count
                    this.listAccount = res.data.list
                }
            })
            .catch((err) => {
                this.$root.isError = true
                if(!navigator.onLine) this.$root.isError = err.message
            })
            if(!this.$root.isError) this.$root.overlay = false
            this.loading=false
        },
        async updateStatusApproval() {
            this.loading=true
            var api = this.$root.api.post.accountSubmission
            await this.$root.api.sentAPI(
            {
                method: 'post',
                api: api,
                form: this.bodyParam
            }).then((res) => {
                if (res.responseStatus.responseCode==='00') {
                    this.getData(this.filterStatus)
                }
            })
            .catch((err) => {
                this.$root.isError = true
                if(!navigator.onLine) this.$root.isError = err.message
            })
            if(!this.$root.isError) this.$root.overlay = false
            this.loading=false
        },
        getColor(status) {
            if (status == 53) return 'green'
            else return 'red'
        },
        updateStatus(item, status) {
            this.passingBodyParam(item, status)
            this.updateStatusApproval()
            this.cancel()
        },
        showDialogRevise(item) {
            this.dialog=true
            this.item=item
        },
        cancel() {
            this.dialog=false
            this.remark=null
            this.item=null
        },
        revise() {
            this.updateStatus(this.item, 52)
        },
        passingBodyParam(item, status) {
            this.bodyParam = {
                accountId: item.account.accountId,
                memberId: item.memberId,
                maintenanceStatus: status,
                remark: this.remark
            }
        }
    },
    mounted() {
        this.getData(this.filterStatus)
    }
}
</script>